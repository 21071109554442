html {
    overflow-x: hidden !important;
}

body {
    background-color: #F7F9FC !important;
    color: #666 !important;
    font-size: 14px !important;
    line-height: 16px;
    overflow-x: hidden !important;
}

table,
th,
td {
    color: #666 !important;
}

.badge.bg-primary.primary-bg {
    background-color: #0D3F5F !important;
}




/* .heder-top {
    color: #eeeeee;
    background-color: #233044;
    padding: 6px 0px;
} */

.header-main {
    background-color: #fff !important;
    color: #9e9e9e !important;
}

.header-right {
    position: relative;
    width: 23%;
}

.MainContent {
    flex: auto !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.modal-header-bg {
    background-color: #D8D8D8 !important;
}

.footer-main {
    padding: 1px 16px;
    background: #FFF;
    position: relative;
    bottom: 0px;
    width: 100%;
}

.lh-20px {
    line-height: 20px !important;
}

.f-22px {
    font-size: 22px !important;
}

.f-20px {
    font-size: 20px !important;
}

.f-16px {
    font-size: 16px !important;
}

.f-15px {
    font-size: 15px !important;
}

.f-14px {
    font-size: 14px !important;
}

.f-13px {
    font-size: 13px !important;
}

.f-12px {
    font-size: 12px !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.gray-color {
    color: #666 !important
}

.gray-icon-color {
    color: #999 !important
}

.red-color {
    color: #d9534f !important;
}

.w-45 {
    width: 45% !important;
}

.w-47 {
    width: 47% !important;
}

/* Buttons Style Bebin */
.button-light {
    color: #333333 !important;
    background-color: #fff !important;
    border: 1px solid #ccc !important;
    border-radius: 3px !important;
}

.button-light:hover {
    color: #333 !important;
    background-color: #e6e6e6 !important;
    border-color: #adadad !important;
}

.button-blue {
    color: #ffffff !important;
    background-color: #0d3f5f !important;
    border: 1px solid #0c3956 !important;
    border-radius: 3px !important;
}

.button-red {
    color: #ffffff !important;
    color: #fff !important;
    background-color: #d9534f !important;
    border: 1px solid #d43f3a !important;
    border-radius: 3px !important;
}

.button-red:hover {
    background-color: #c9302c !important;
    border-color: #ac2925 !important;
}

/* Buttons Style End */

.icon-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    float: left;
    padding: 6px 10px;
    text-align: center;
    text-decoration: none;
    line-height: 1;
    color: inherit;
    text-transform: uppercase;
    border-radius: 5px;
    position: relative;
    background-color: #fff;
    /* box-shadow: 1px 1px 5px 1px rgba(138, 137, 137, 0.2); */
    color: #999;
    margin-right: 10px;
}

.icon-box:last-child {
    margin-right: 0px !important;
}

.icon-box:hover {
    background-color: rgb(249, 249, 249);
}

.icon-title {
    font-size: 13px;
    margin-top: 7px;
}

.icon-bg-rounded {
    border-radius: 50%;
    background-color: #9E9E9E;
    width: 30px !important;
    height: 30px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.icon-bg-rounded.notification {
    background-color: rgb(168, 196, 92);
}

.icon-bg-rounded.notification .notifi-text {
    position: absolute;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 500;
    line-height: 13px;
    padding: 3px 6px;
    left: 22px;
    top: -8px;
    background-color: #f44;
    color: #fff;
}

.icon-bg-rounded.savedLater .SL-text {
    position: absolute;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 500;
    line-height: 13px;
    padding: 3px 6px;
    left: 22px;
    top: -8px;
    background-color: #888;
    color: #fff;
}

.h-190px {
    height: 190px !important;
}

.light-gray-btn {
    background-color: #dcdcdc !important;
    padding: 0px 7px !important;
    color: #666 !important;
    display: flex !important;
    align-items: center !important;
    height: 25px !important;
}

.light-gray-btn font {
    display: flex !important;
    align-items: center !important;
}

.home-banner-bg {
    background-image: url('/src/images/fitImage.png') !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    box-shadow: inset 0 0 0 100vmax rgba(107, 109, 113, .8);

}

.black {
    color: #000 !important;
}

.f-32px {
    font-size: 32px !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.hover-underline:hover {
    text-decoration: underline !important;
}

.InformationTabs .tab-content {
    border: none !important;
    box-shadow: 0 0 15px 0 rgb(0 0 0 / 20%) !important;
}

.InformationTabs .nav-tabs {
    margin-bottom: 0px !important;
}

.InformationTabs .nav-tabs .nav-item .nav-link {
    background-color: #E3E3E3 !important;
    border: 1px solid #cdcccc !important;
    font: 16px !important;
    color: #555 !important;
    width: 100%;
}

.InformationTabs .nav-tabs .nav-item {
    width: 20% !important;
}

.InformationTabs .nav-tabs .nav-item:nth-child(2) {
    margin-left: 5px;
    margin-right: 5px;
}

.InformationTabs .nav-tabs .nav-item .nav-link.active {
    background-color: #FFF !important;
    border: 1px solid #E3E3E3 !important;
}

.border-top-none {
    border-top: none !important;
    border-top-width: 0px !important;
    border-top-color: #fff !important;
}

.activity-accordion .accordion .accordion-item {
    border-left: none !important;
    border-right: none !important;
    border-radius: 0px !important;
}

.activity-accordion .accordion h6 {
    color: #0d3f5f !important;
}



.activity-accordion .accordion .accordion-item:nth-child(1) {
    border-top: none !important;
}

.activity-accordion .accordion .accordion-item .accordion-header .accordion-button.collapsed {
    background-color: #E3E3E3 !important;
    padding: 2px 5px;
    border: 1px solid #cdcccc !important;
    border-radius: 5px !important;
    color: #666 !important;
}

.activity-accordion .accordion .accordion-item .accordion-body {
    padding: 0px !important;
}

.activity-accordion .accordion .accordion-item .accordion-header .accordion-button {
    color: #666 !important;
    background-color: #E3E3E3 !important;
    padding: 2px 5px;
    border: 1px solid #cdcccc !important;
    border-radius: 5px !important;
}

.activity-accordion .accordion .accordion-item .accordion-header .accordion-button.collapsed::after {
    background-image: url('/src/images/add.png');
}

.activity-accordion .accordion .accordion-item .accordion-header .accordion-button::after {
    content: '';
    background-image: url('/src/images/minus.png');
    margin-left: 5px;
    background-size: 17px;
    background-position: center;
}

.activity-accordion .accordion .accordion-item.notification-setting-tab-item .accordion-header .accordion-button.collapsed::after {
    display: none !important;
}

.activity-accordion .accordion .accordion-item.notification-setting-tab-item .accordion-header .accordion-button::after {
    display: none !important;
}

.activity-accordion .accordion .accordion-item.notification-setting-tab-item .accordion-header .accordion-button.collapsed::before {
    background-image: url('/src/images/add.png') !important;
}

.activity-accordion .accordion .accordion-item.notification-setting-tab-item .accordion-header .accordion-button::before {
    content: '';
    background-image: url('/src/images/minus.png') !important;
    margin-right: 5px !important;
    background-size: 17px !important;
    background-position: center !important;
    width: 17px;
    height: 17px;
}

.practice-label {
    padding: 1px 4px;
    background-color: #fff !important;
    color: #000;
    border: 1px solid #000;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 10px;
}

.accordion-content {
    padding: 17px 5px;
    border-top: 1px solid #dee2e6;
}

.accordion-content:nth-child(1) {
    border-top: none !important;
}

/* Image Upload Begin */
.image-upload {
    text-align: center;
}

.image-upload input {
    display: none;
}

.image-upload label {
    display: block;
    color: #000;
    background: #E3E3E3;
    padding: .3rem .6rem;
    font-size: 115%;
    cursor: pointer;
}

.image-upload label .icon {
    font-size: 125%;
    margin-right: .3rem;
}

.image-upload label:hover .icon {
    animation: shake .35s
}

.image-upload img {
    max-width: 175px;
    display: none;
}

.image-upload span {
    display: none;
    text-align: center;
    cursor: pointer;

}

/* Image Upload end */
/* file-attach Begin */
.file-attach {
    text-align: center;
}

.file-attach input {
    display: none;
}

.file-attach label {
    display: block;
    color: #000;
    padding: 4px 8px !important;
    font-size: 14px !important;
    cursor: pointer;
    border: 1px solid #6c757d;
    border-right: none !important;
}

.file-attach label .icon {
    font-size: 125%;
    margin-right: .3rem;
}

.file-attach label:hover .icon {
    animation: shake .35s
}

.file-attach img {
    max-width: 175px;
    display: none;
}

.file-attach span {
    display: none;
    text-align: center;
    cursor: pointer;

}

/* file-attach end */

/* file-attach In-medai-modal Begin */

.In-medai-modal.file-attach label {
    display: block;
    color: #000;
    padding: 8px 12px !important;
    font-size: 14px !important;
    cursor: pointer;
    border: 1px solid #6c757d !important;
}

.file-holder {
    width: 100%;
    border: 2px dotted #6c757d;
    padding: 9px 12px !important;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* file-attach In-medai-modal end */

/* Video Upload Begin */
.video-upload {
    text-align: center;
}

.video-upload input {
    display: none;
}

.video-upload label {
    display: block;
    color: #000;
    background: #E3E3E3;
    padding: .3rem .6rem;
    font-size: 115%;
    cursor: pointer;
}

.video-upload label .icon {
    font-size: 125%;
    margin-right: .3rem;
}

.video-upload label:hover .icon {
    animation: shake .35s
}

.video-upload img {
    max-width: 175px;
    display: none;
}

.video-upload span {
    display: none;
    text-align: center;
    cursor: pointer;

}

/* Video Upload end */
@keyframes shake {
    0% {
        transform: rotate(0deg)
    }

    25% {
        transform: rotate(10deg)
    }

    50% {
        transform: rotate(0deg)
    }

    75% {
        transform: rotate(-10deg)
    }

    100% {
        transform: rotate(0deg)
    }
}

#new-item.dropdown-toggle::after {
    display: none !important;
}

#new-item.dropdown-toggle::before {
    display: inline-block;
    content: url('/src/images/plusblue.png');
    transform: scale(0.6);
    position: relative;
    top: 2px;
    right: 3px;
}

#new-item.dropdown-toggle:hover::before {
    display: inline-block;
    content: url('/src/images/plus.png');
    transform: scale(0.6);
    position: relative;
    top: 2px;
    right: 3px;
}



.default-btn {
    color: #333 !important;
    background-color: #fff !important;
    border-color: #ccc !important;
}

.swal2-cancel.default-btn {
    color: #333 !important;
    background-color: #fff !important;
    border: 1px solid #ccc !important;
    border-radius: 0.375rem !important;
}

.default-btn:hover {
    color: #333 !important;
    background-color: #e6e6e6 !important;
    border-color: #adadad !important;
}

.swal2-cancel.default-btn:hover {
    color: #333 !important;
    background-color: #e6e6e6 !important;
    border: 1px solid #adadad !important;
    border-radius: 0.375rem !important;
}

.swal2-cancel.default-btn:focus,
.swal2-confirm {
    box-shadow: none !important;
}

.default-btn-icon {
    position: relative;
    bottom: 2px;
    right: 4px;
}

.shadow-modal .modal-content {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.badge-box {
    cursor: pointer;
}

.badge-box:hover {
    background: #e0e0e0 !important;
    border-radius: 5px !important;
}

.badge.badge-dark-blue {
    background-color: #0D3F5F !important;
    color: #fff !important;
}

.badge.badge-gray {
    background: #678ea4 !important;
    color: #fff !important;
}

.badge.badge-blue-gray {
    background: #3c6685 !important;
    color: #fff !important;
}

.badge.badge-light-blue {
    background: #2b9cb2 !important;
    color: #fff !important;
}

.custom-badge {
    padding: 1px 5px;
    background: #fff;
    border: 1px solid;
    white-space: nowrap;
    border-radius: 5px;
    font-weight: 400;
    text-transform: uppercase;
    font-size: .8em;
    vertical-align: middle;
    margin-right: 5px;
}

.template-sort.dropdown .dropdown-toggle {
    width: 100%;
    color: #333 !important;
    background-color: #fff !important;
    border-color: #ccc !important;
}

#new-item {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100%;
}

.template-sort.dropdown #new-item.dropdown-toggle::before {
    display: none !important;
}

.template-sort.dropdown #new-item.dropdown-toggle::after {
    display: inline-block !important;
    transform: scale(1.1) !important;
    top: 1px !important;
    left: 4px !important;
    position: relative;
}

.template-withouticon.dropdown .dropdown-toggle {
    width: 100%;
    color: #333 !important;
    background-color: #fff !important;
    border-color: #ccc !important;
}

.template-withouticon.dropdown #new-item.dropdown-toggle::before {
    display: none !important;
}

.template-withouticon.dropdown #new-item.dropdown-toggle::after {
    display: inline-block !important;
    transform: scale(1.1) !important;
    top: 1px !important;
    left: 0px !important;
    position: relative;
}

.w-100.dropdown .dropdown-toggle:hover {
    color: #333 !important;
    background-color: #e6e6e6 !important;
    border-color: #adadad !important;
}

.empty-category {
    padding: 7px;
    margin: 5px;
    text-align: center;
    background-color: #d9edf7;
    border-color: #bce8f1;
    color: #31708f;
}

.chain {
    padding: 1px 5px;
    background: #fff;
    border: 1px solid;
    white-space: nowrap;
    border-radius: 5px;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 10px;
    vertical-align: middle;
}

.type-edit-practice {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.no-template-alert {
    padding: 7px;
    background-color: #fcf8e3;
    color: #8a6d3b;
    border: 1px solid #faebcc;
    border-radius: 4px;
}

.icon-time-picker {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #D8D8D8;
    border-radius: 0px 5px 5px 0px;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 30px;
}

.more-task-link {
    color: #0d3f5f !important;
    text-decoration: none;
}

.more-task-link:hover {
    text-decoration: underline !important;
}

.w-95px {
    width: 95px !important;
}

.w-100px {
    width: 100px !important;
}

.w-120px {
    width: 120px !important;
}

.w-177px {
    width: 177px !important;
}

.gray-label {
    background: #999;
    border-radius: 3px;
    text-align: center;
    color: #fff;
    display: inline-block;
    min-width: 20px;
    padding: 0 2px;
    font-size: 11px;
    line-height: 16px;
}

.Side-Menu {
    list-style: none !important;
}

/* SiNav Css Begin */
.side-nav {
    width: 100%;
    font-size: 14px;
}

.side-nav input[type=checkbox] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    display: block;
    cursor: pointer;
}

.side-nav ul {
    margin: 0;
    padding: 0;
}




/* .side-nav a::before {
    content: "";
    display: block;
    height: 80%;
    margin-left: -10px;
    margin-top: -3px;
    opacity: .5;
    position: absolute;
    width: 3px;
    background-color: #999999;
} */

.side-nav li {
    position: relative;
    width: 100%;
    list-style: none;
    color: #fff;
    transition: 0.5s;
}


.side-nav ul>li.sub>a:after {
    position: relative;
    float: right;
    content: '';
    margin-top: 6px;
    font-size: 1.2em;
    vertical-align: middle;
    transition: 0.3s;
    background-image: url('/public/images/arrow-down-sign-to-navigate-gray.png');
    background-size: cover;
    height: 12px;
    width: 12px;
}

.side-nav ul>li.sub>a.active:after {
    background-image: url('/public/images/arrow-down-sign-to-navigate-blue.png') !important;
}

.side-nav input[type=checkbox]:hover {
    background-color: #ebebebb9 !important;
}

.side-nav .submenu {
    max-height: 0;
    overflow: hidden;
    border-top: none;
    transition: max-height 0.3s ease-in-out;
}

.side-nav input[type=checkbox]:checked~.submenu {
    max-height: 999px;
}



.side-nav input[type=checkbox]:checked~a:after {
    transform: rotate(180deg);
    background-image: url('/public/images/arrow-down-sign-to-navigate-blue.png');
}

.side-nav .submenu a {
    padding: 15px 20px;
    font-size: 12px !important;
    line-height: 15px !important;
    position: relative;
}





.side-nav .submenu li {
    list-style-position: inside;
    list-style-type: none;
    padding-left: 30px;
}

.side-nav .submenu li:hover {
    background: #ebebebb9 !important;
}

.side-nav .submenu li.sub {
    list-style: none;
}

/* SiNav Css End */


/* AccordionMenu Begin */
.Acc-menu {
    list-style: none !important;
}

.Acc-menu .Acc-menu-item {
    padding: 10px 15px;
}

.Accordion-menu.accordion .accordion-item {
    border: none !important;
}

.Accordion-menu.accordion .accordion-item .accordion-header .accordion-button.collapsed {
    background-color: #fff !important;
    padding: 10px;
    border: none !important;
    color: #666 !important;
}


.Accordion-menu.accordion .accordion-item .accordion-body {
    padding: 0px !important;
}

.Accordion-menu.accordion .accordion-item .accordion-header .accordion-button {
    color: #666 !important;
    background-color: #ebebebb9 !important;
    padding: 10px;
    border: none !important;
    border-radius: 0px !important;
}

.Accordion-menu.accordion .accordion-item .accordion-header .accordion-button:focus {
    border: none !important;
    box-shadow: none !important;
}

.Acc-menu-link {
    text-decoration: none;
}

.Accordion-menu.accordion .accordion-button::after {
    background-size: 15px !important;
}

/* AccordionMenu End */

.tagify .css-13cymwt-control {
    min-height: 31px !important;
}

.tagify .css-t3ipsp-control {
    border-color: #86b7fe !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%) !important;
}

.subscribedCalendarColorbox {
    width: 13px;
    height: 13px;
    margin: 2px 3px 0 0;
    display: inline-block;
    border: 1px solid;
    border-radius: 3px;
    float: left;
    border-color: rgb(105, 176, 186);
    background-color: rgb(105, 176, 186);
    cursor: pointer;
}

.search-icon {
    position: absolute;
    top: 8px;
    right: 5px;
}

.w-176px {
    width: 176px;
}

.b-shadow {
    box-shadow: 0 0 15px 0 rgb(0 0 0 / 20%) !important;
}

.h-230px {
    height: 230px !important;
}

.custom-dropdown .dropdown-toggle:after {
    display: none;
}

.accountLink .show.popover {
    margin-top: 5px;
    min-width: 350px;
    border-radius: 5px !important;
}

.form-control:focus {
    border-color: #0D3F5F;
    outline: 0;
    box-shadow: 0 0 0 4px rgb(13 63 95 / 15%) !important;
}

.modal-backdrop.show,
.modal.show {
    z-index: 9999;
}





@media only screen and (min-width:1200px) {
    .d-flex-column-xl {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .navbar-nav .nav-link {
        padding: 4px !important;
    }

    .w-lg-85 {
        width: 85% !important;
    }

    .w-lg-100 {
        width: 100% !important;
    }

    .w-lg-50 {
        width: 500% !important;
    }

    .w-lg-150px {
        width: 150px !important;
    }

    .w-lg-180px {
        width: 180px !important;
    }
}

@media only screen and (min-width: 992px) {
    .w-col {
        flex: 0 0 auto;
        width: 20.833333335% !important
    }

    #task-modal.modal-lg {
        width: 850px !important;
        --bs-modal-width: 850px !important;
    }

    #manageList.modal-lg,
    #managePlace.modal-lg {
        width: 900px !important;
        --bs-modal-width: 900px !important;
    }

    .w-lg-100 {
        width: 100% !important;
    }

    .ps-lg-0 {
        padding-left: 0px !important;
    }

    .pe-lg-0 {
        padding-right: 0px !important;
    }

    .px-lg-0 {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .header-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 80px;
        position: relative;
        top: -8px;
    }

    .w-55 {
        width: 55% !important;
    }

    .w-95 {
        width: 96% !important;
    }

    .sf-header .dropdown {
        width: 40% !important;
    }

    .sf-header .dropdown-menu {
        width: 100% !important;
    }

    #responsive-navbar-nav {
        justify-content: space-between !important;
    }

    .tool-box {
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .tool-box-inner {
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        position: relative;
        top: 10px;
    }

    .tool-box-top {
        background-color: #efefef;
        border-radius: 5px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 6px;
        padding-top: 6px;
        width: 100%;
        margin-left: auto;
    }
}

@media only screen and (min-width:600px) {
    .logo {
        width: 170px;
    }
}

@media only screen and (max-width:1199px) and (min-width:992px) {
    .header-right {
        position: relative;
        width: 28% !important;
        top: -12px !important;
    }

    .icon-box {
        padding: 6px 0px !important;
    }

    .logo {
        width: 150px !important;
    }

    .tool-box-top {
        width: 100%;
    }


}

@media only screen and (max-width:991px) {
    .header-right {
        position: relative;
        width: 40%;
    }

    .logo {
        width: 150px !important;
    }

    .tool-box {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .tool-box-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        position: relative;
    }

    .tool-box-top {
        position: absolrelativeute;
        background-color: #efefef;
        border-radius: 5px;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 3px;
        margin-top: 10px;
        width: 100%;
    }

    .pb-sm-20px {
        padding-bottom: 20px !important;
    }

    .pb-sm-15px {
        padding-bottom: 15px !important;
    }

    .pb-sm-10px {
        padding-bottom: 10px !important;
    }

    .icon-box {
        width: 100%;
    }

    .me-3.navbar-nav {
        margin-right: 0px !important;
    }
}

@media only screen and (max-width:800px) and (min-width:500px) {
    .header-right {
        position: relative;
        width: 70%;
    }
}

@media only screen and (max-width:500px) {
    .header-right {
        position: relative;
        width: 100%;
    }
}

@media only screen and (min-width:768px) {
    .w-md-100 {
        width: 100% !important;
    }
}

.has-error input {
    border-color: red;
}

.popover-body {
    padding: 5px 8px !important;
}

.template-sort.f-12px * {
    font-size: 12px !important;
}

.template-sort .dropdown-toggle.btn {
    padding: 6px 12px !important;
}

input[type="text"].form-control-sm,
input[type="password"].form-control-sm {
    height: 32px !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.w-15 {
    width: 15%;
}

.text-link-color {
    color: #666;
}

.text-link-color:hover {
    color: #23527c;
    text-decoration: underline;
}

.badge-box-idea {
    display: inline-block;
    width: 12px;
    height: 12px;
    background-color: #0d3f5f;
    margin-top: 4px;
}

.width-fit-content {
    width: fit-content;
}


.tree {
    margin-bottom: 0px !important;
}

.tree,
.tree ul {
    /* indentation */
    padding: 0;
    list-style: none;
    color: #666;
    position: relative;
}

.tree ul {
    margin-left: .5em
}

/* (indentation/2) */

.tree:before,
.tree ul:before {
    content: "";
    display: block;
    width: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -1px;
    border-left: 1px dashed;
}

ul.tree:before {
    border-left: none
}

.tree li {
    margin: 5px 0;
    padding-left: 35px;
    /* indentation + .5em */
    line-height: 2em;
    /* default list item's `line-height` */
    font-weight: bold;
    position: relative;
}

.tree>li {
    padding-left: 0px;

}

.tree container,
.tree folder {
    display: block;
}

.tree container icon,
.tree folder icon {
    background-repeat: no-repeat;
    background-position: 4px center;
    padding-left: 24px;
}

.tree folder.selected {
    background-image: linear-gradient(to bottom, #e4eef5, #c0d1db);
    border-radius: 5px;
}

.tree folder:hover {
    background-image: linear-gradient(to bottom, #e9f5e7, #c3d9bf);
    border-radius: 5px;
}

.tree li:before {
    content: "";
    display: block;
    width: 25px;
    /* same with indentation */
    height: 0;
    border-top: 1px dashed;
    margin-top: -1px;
    /* border top width */
    position: absolute;
    top: 16px;
    /* (line-height/2) */
    left: 4px;
}

ul.tree>li:before {
    border-top: none;
}

.tree li:last-child:before {
    background: #ffffff00;
    ;
    /* same with body background */
    height: auto;
    top: 1em;
    /* (line-height/2) */
    bottom: 0;
}

.child {
    margin-left: 20px;
}

.tagify-custom {
    min-height: 32px !important;
    border: 1px solid #ced4da !important;
    border-color: #ced4da !important;
    box-shadow: none !important;
}

.tagify-custom .react-select__multi-value,
.tagify-custom .react-select__input-container {
    margin: 0px 2px !important;
}

.tagify-custom .react-select__multi-value {
    height: 21px !important;
}

.tagify-custom .react-select__multi-value__label {
    font-size: 84% !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    padding-left: 7px !important;
    padding-right: 0px !important;
}

.btn:disabled {
    pointer-events: none !important;
    cursor: no-drop !important;
}

.active-tree-node {
    color: #369 !important;
}

.drag-li {
    height: 59px;
    padding: 0 20px;
    background-color: #fff;
    border-bottom: 1px solid #efefef;
    box-sizing: border-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    user-select: none;
    color: #333;
}

.modal li.drag-li {
    position: relative !important;
    z-index: 99999 !important;
}

.checklist .dropdown-menu.show {
    z-index: 9999;
}

.dropdown-item:focus,
.dropdown-item:hover,
.dropdown-item:active {
    color: #fff !important;
    background-color: #0D3F5F !important;
}

.header-dropdown .dropdown-item:hover *,
.dropdown-item:hover div {
    color: #fff !important;
}

.dropdown-item:hover img {
    filter: brightness(250%) saturate(0%) grayscale(100%);
}

.header-actions-dropDown.dropdown .dropdown-toggle {
    color: #0D3F5F !important;
    border-color: #0D3F5F !important;
}

.header-actions-dropDown.dropdown .dropdown-toggle:hover {
    color: #fff !important;
    background-color: #0D3F5F !important;
    border-color: #0D3F5F !important;
}

.gray-text {
    color: #9E9E9E !important
}

.remove-checkList-btn {
    background-color: #e6e6e6 !important;
    border: 1px solid #c6c7c8 !important;
}

.checklist-input:focus {
    box-shadow: none !important;
    border-color: #c6c7c8 !important;
}

.option-input {
    margin-bottom: 5px;
}

.option-input:last-child {
    margin-bottom: 16px;
}

.popover-scrollable {
    overflow: hidden scroll;
    height: 290px;
}

input[type="checkbox"][name="displayMode"] {
    pointer-events: none !important;
}

.dropdown-item-active,
.dropdown-item-active div {
    color: #fff !important;
    background-color: #0D3F5F !important;
    margin: 1px 0px;
}

.bg-task-recipient {
    padding: 5px !important;
}

.bg-task-recipient:hover {
    background: #e6e7e8;
}

.LoaderWrapper {
    position: fixed;
    z-index: 9999999999;
    background-color: #333333ab;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.discardTask-alert .swal2-title {
    font-size: 1.2rem !important;
    font-weight: 500 !important;
}

.bg-task-recipient.light-opacity {
    opacity: 0.5;
    pointer-events: none;
}

.lh-12px {
    line-height: 12px !important;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.dropdown.btn-group.dropdown-color {
    width: 170px !important;
    height: 33px !important;
}

.dropdown.btn-group.dropdown-color .default-btn {
    width: 140px !important;
}

.dropdown-color .dropdown-item.active {
    background-color: #0D3F5F !important;
    color: #fff !important;
}

.dropdown-toggle-split.dropdown-split-primary {
    background-color: #0D3F5F !important;
    border-color: #0D3F5F !important;
}

.dropdown-toggle-split.dropdown-split-primary::after {
    color: #fff !important;
}

.btn-group.custom-btn-group {
    height: 33px !important;
}

.left-sidebar-item:not(:first-child) {
    margin-top: 1.5rem;
}

.left-sidebar-item:last-child {
    margin-bottom: 1.5rem !important;
}

.left-sidebar-item.disable {
    pointer-events: none !important;
}

.theme-color-picker .colorpick-eyedropper-input-trigger {
    display: none !important;
}

.uploaded-logo {
    max-height: 100px;
    object-fit: contain;
}

.remove-mr-formcheck .form-check {
    margin-right: 5px !important;
}

.calendar-pdf-checks .form-control {
    width: 45px !important;
    border-radius: 3px !important;
    padding: 2px 5px !important;
}
/* Main style css file by rashid */

.list-entries-content {
    display: inline-block;
    /* margin: 0px 10px; */
    height: 230px;
    background-color: #fff;
    box-shadow: 0 0 15px 0 rgb(0 0 0 / 20%);
    margin-top: 15px !important;
    width: 100% !important;
    cursor: pointer;
    padding: 0px 8px;
}

.module-dragable {
    height: 230px;

}

.list-entries-content:hover {
    box-shadow: inset -1px -1px 20px 4px #d4d4d4;
}

.fs-14 {
    font-size: 14px;
}

.fs-12 {
    font-size: 12px;
}

.fw-700 {
    font-weight: 700;
}

.border-ends {
    border-bottom: 1px solid #dee2e6;
    margin-top: 10px;
}

.blue-state {
    background-color: #077ce0;
    color: #fff;
    width: 12px;
    height: 12px;
    font-weight: 700;
    display: inline-block;
}

.yellow-state {
    background-color: #fbd300;
    color: #a4a4a4;
    width: 12px;
    height: 12px;
    font-weight: 700;
    display: inline-block;
}

.ng-badge {
    background-color: #d4f4f7;
    color: #306269;
    padding: 3px 6px;
    border-radius: 4px;
    font-size: 11px;
    font-weight: 700;
}

.border-left-1 {
    border-left: 3px solid #888;
    margin: 3px 0px;
}

.overflow-yxis {
    overflow-y: hidde !important;
}

.overflow-y-hidden {
    overflow-y: hidde !important;
}

.border-1 {
    border-left: 3px solid #fc9136;
    margin: 3px 0px;
}

.text-orange {
    color: #fc9136;
}

.text-sky {
    color: #04a0a0;
}

.border-sky {
    border-left: 3px solid #04a0a0;
    margin: 3px 0px;
}

.NeuesteNewstable .datetimecolumn {
    text-align: end !important;
}

.w-23 {
    width: 23%;
}

.NeuesteNachrichtentable .datetimecolumn {
    text-align: end !important;
}

.footer-sticky {
    position: sticky;
    bottom: 0;
    background-color: inherit !important;
    z-index: 1055;
}

.p-td {
    padding: 10px 5px !important;
}

.boxShadow {
    box-shadow: 0 0 15px 0 rgb(0 0 0 / 20%);
}

.info-list li a {
    text-decoration: none;
}

.info-list li a:hover {
    text-decoration: underline;
}

/* .info-list li a:active{
    border-left: 3px solid red;
} */
.border-left-item a:before {
    content: "";
    height: 10%;
    margin-left: -10px;
    margin-top: -2px;
    opacity: .5;
    position: absolute;
    width: 3px;
    background: #0d3f5f;
}

.active-display-option:active {
    color: #0d3f5f;
}

.light-yellow {
    background-color: #ffc;
}

.btn-group-blue-01,
#custom-bg-dropdown {
    background-color: #0D3F5F !important;
}

.integration-preview-img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

.tutorial-card {
    height: 280px !important;
}

.tutorial-card:hover {
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, .25);
}

.width-8 {
    width: 8%;
}

.width-15 {
    width: 15%;
}

.h-320px {
    height: 320px !important;
}

.h-350px {
    height: 350px;
}

/* back to top button in css */


.back-to-top:hover {
    background: #3484b6;
}

.home-banner-img:active {
    background: #b3cee1;
}

.calender-dropdown .dropdown {
    position: relative !important;
}

#dropdown-button-drop-custom-calender {
    width: 100% !important;
}

.calender-dropdown #dropdown-button-drop-custom-calender.dropdown-toggle::after {
    position: absolute !important;
    top: 50%;
    right: 12px;
}

.homePage-select-mb div {
    margin-bottom: 0px !important;
}


.toggle-text-group {
    opacity: 0;
    max-height: auto;
    overflow: hidden;
    transition: opacity 0.9s, max-height 0.9s;
}

.show-text-group {
    opacity: 1;
    /* max-height: 100px; */
}

.hide-text-group {
    opacity: 0.9s;
    max-height: 0;
    transition: opacity 0.9s, max-height 0.9s;
}